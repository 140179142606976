import React, { useMemo } from 'react';
import { Box, Button, Link } from 'rebass';
import { useHistory } from 'react-router-dom';
import { useClickAway } from 'react-use';
import * as R from 'ramda';
import DefaultLogoIcon from './icons/DefaultLogoIcon';
import AccountButton from './buttons/AccountButton';
import { LINK_DEFAULT_STYLE, LogoLinks } from '../theme/constants';
import BackIcon from './icons/BackIcon';
import AccountDropdown from './AccountDropdown';
import { theme } from '../contexts/AppTheme';
import { AppSelect, AppType } from '../appSelect';
import { appType } from '../env';
import HomeButton, { HomeButtonType } from './buttons/HomeButton';

const HEADER_STYLES_DEFAULT: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const HEADER_STYLES_ABSOLUTE: React.CSSProperties = {
  position: 'fixed',
  zIndex: 2,
  top: 0,
  left: 0,
  right: 0,
};

interface Props {
  isBack?: boolean;
}

const Header: React.FC<Props> = ({ isBack }) => {
  const history = useHistory();

  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

  const dropdownRef = React.useRef<HTMLElement>(null);

  const headerStyles = React.useMemo(
    () =>
      isBack
        ? R.mergeRight(HEADER_STYLES_DEFAULT, HEADER_STYLES_ABSOLUTE)
        : HEADER_STYLES_DEFAULT,
    [isBack],
  );

  const logoLink = AppSelect({
    [AppType.BLACKROLL]: LogoLinks.BLACKROLL,
    [AppType.WELLSYSTEM]: LogoLinks.WELLSYSTEM,
    default: LogoLinks.DEFAULT,
  });

  const logoFill = isBack ? theme.colors.white : theme.colors.primary;

  const LogoIcon = useMemo(() => {
    switch (appType) {
      case AppType.BLACKROLL:
      case AppType.WELLSYSTEM:
        return <HomeButton color={logoFill} type={HomeButtonType.HEADER} />;
      default:
        return <DefaultLogoIcon width="9.375em" fill={logoFill} />;
    }
  }, [logoFill]);

  const showDropdown = React.useCallback(() => setIsDropdownVisible(true), []);

  const hideDropdown = React.useCallback(() => setIsDropdownVisible(false), []);

  useClickAway(dropdownRef, hideDropdown);

  return (
    <Box mt={8} ml={[5, 10, 11]} mr={[5, 10, 11]} style={headerStyles}>
      <Box display="flex">
        {isBack && (
          <Button
            variant="unstyled"
            onClick={history.goBack}
            mr={[3, 7, 8]}
            style={{
              borderRadius: '100%',
            }}
          >
            <BackIcon width="2.5rem" />
          </Button>
        )}
        <Link
          href={logoLink}
          target="_blank"
          style={{
            ...LINK_DEFAULT_STYLE,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {LogoIcon}
        </Link>
      </Box>
      <Box
        style={{ position: 'relative' }}
        onMouseEnter={showDropdown}
        onMouseLeave={hideDropdown}
        onClick={showDropdown}
      >
        <AccountButton />
        <Box pt={2}>{isDropdownVisible && <AccountDropdown />}</Box>
      </Box>
    </Box>
  );
};

export default React.memo(Header);
